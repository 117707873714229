import gmail from "../assets/gmail.png";
import mobile from "../assets/mobile.png";
import figma from "../assets/figma.png";
import git from "../assets/git.png";
import mu5 from "../assets/mu5.png";
import node from "../assets/node.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";

export default {
  gmail,
  mobile,
  figma,
  git,
  mu5,
  node,
  react,
  redux,
  sass,
  typescript,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
};
