import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "4plana1k",
  dataset: "production",
  apiVersion: "2022-02-01",
  useCdn: true,
  token:
    "skVjJAccu6xje8oTV1V1ldPBATLQbv2qI19peZZLwyEw2cbHQUHttJcub4JafspZdwILpJ6qMTipfV0eFycxMj2bJFPkaPBilr5HUbagPKk5FnMzVhqQC6efP9zV8mn4XCkcKR2a18GtMDDpq6wn7OVXpnVlZq7gvILyAm8T6pcPmJsaKOje",
  ignoreBrowserTokenWarning: true

});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
